:root {
    --border-color: #f0f0f0;
}


/*Used !important here, as the css to override comes from a lib we do not control!*/
.ant-layout-header {
    background: white !important;
    padding: 0 !important;
}

.spaced-button {
    margin-left: 5px;
    margin-right: 5px;
    border: none !important;
}

.header-button {
    margin-left: 16px;
    margin-right: 10px;
    border: none !important;
}

.textCenter {
    text-align: center;
}

.main-content {
    padding: 0 10px 10px;
    background-color: white;
}

.app-bar {
    border-bottom: 1px lightgrey solid;
}

.green-background {
    background-color: #00AA00 !important;
}

.white-font {
    color: white !important;
}

@media only screen and (max-width: 400px) {
    .header-title {
        font-size: 15px;
    }
}

@media only screen and (min-width: 401px) {
    .header-title {
        font-size: 20px;
    }
}

.header-title {

}

.drawer-icon {
    font-size: 15px;
}

.footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
}

.sticky {
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 1000;
    background-color: white;
    padding-top: 5px;
    padding-bottom: 5px;
    border-bottom: var(--border-color) solid 1px;
}

.price-style {
    text-align: center;
    font-size: 20px;
}

.price-style-statistics {
    text-align: center;
    font-size: 16px;
    padding: 4px;
    margin: 2px;
    border: var(--border-color) solid 1px;
    border-radius: 5px;
}


.card-alike {
    text-align: center;
    font-size: 16px;
    padding: 4px;
    margin: 2px;
    border: var(--border-color) solid 1px;
    border-radius: 5px;
}


.card-alike:hover {
    cursor: pointer;
    transition: box-shadow 0.3s, border-color 0.3s;

}

.income-color {
    color: green;
}

.expenses-color {
    color: red;
}


.no-margin {
    margin: 0;
}


h1 {
    display: block;
    font-size: 2em;
    margin: 0.5em 0;
    font-weight: bold;
}

h2 {
    display: block;
    font-size: 1.5em;
    margin: 0.5em 0;
    font-weight: bold;
}

h3 {
    display: block;
    font-size: 1.17em;
    margin: 0.5em 0;
    font-weight: bold;
}

h4 {
    display: block;
    margin: 0.5em 0;
    font-weight: bold;
}

h5 {
    display: block;
    font-size: .83em;
    margin: 0.5em 0;
    font-weight: bold;
}

h6 {
    display: block;
    font-size: .67em;
    margin: 0.5em 0;
    font-weight: bold;
}
