@media only screen and (max-width: 700px) {
    .sider-invisible {
        display: none;
    }
}

@media only screen and (min-width: 700px) {
    .drawer-invisible {
        display: none;
    }
}

